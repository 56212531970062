<template>
  <Toast position="bottom-right" />
  <form @submit.prevent="save">
    <div class="grid grid-cols-3 gap-x-8 mb-6">
      <div>
        <FormInputText
          v-model="form.name"
          :id="`name`"
          :label="$t('fullName')"
          :errors="errors.name"
        />
      </div>
      <div>
        <FormInputText
          v-model="form.email"
          :id="`email`"
          label="E-mail"
          :errors="errors.email"
        />
      </div>
      <div>
        <FormInputText
          v-model="form.phone"
          :id="`phone`"
          :label="$t('phone')"
          :errors="errors.phone"
        />
      </div>
    </div>
    <div class="grid grid-cols-3 gap-x-8 mb-6">
      <div>
        <FormInputText
          v-model="form.identifier"
          :id="`identifier`"
          :label="$t('userIdentifier')"
          :errors="errors.identifier"
        />
      </div>
    </div>
    <hr />
    <div class="my-6">
      <label class="block mb-2 font-bold" for="name">{{ $t('status') }}</label>
      <span class="field-radiobutton mr-5">
        <RadioButton
          inputId="status0"
          name="status"
          :value="0"
          v-model="form.status"
          class="align-middle mr-2"
        />
        <label for="status0">{{ $t('inactive') }}</label>
      </span>
      <span class="field-radiobutton">
        <RadioButton
          inputId="status1"
          name="status"
          :value="1"
          v-model="form.status"
          class="align-middle mr-2"
        />
        <label for="status1">{{ $t('active') }}</label>
      </span>
    </div>
    <div></div>
    <div class="pt-2">
      <Button
        type="button"
        :label="$t('save')"
        :loading="submitting"
        @click="save"
        icon="pi pi-save"
        class="mr-5"
      />
      <Button
        type="button"
        :label="$t('delete')"
        @click="deleteUser"
        icon="pi pi-trash"
        class="p-button-danger"
      />
    </div>
  </form>
  <!-- Confirm delete user -->
  <ConfirmDialog group="approve" :draggable="false" class="w-1/3">
    <template>
      <div>
        <span>Вы действительно хотите удалить пользователя?</span>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import FormInputText from '@/components/form/FormInputText.vue'
import { ref } from 'vue'
import adminUsersApi from '@/api/admin/users'
import { useToast } from 'primevue/usetoast'
import i18n from '@/i18n'
import router from '@/router'
import { useConfirm } from 'primevue/useconfirm'

export default {
  props: ['user'],
  components: { FormInputText },
  setup(props) {
    const form = ref(props.user)
    const errors = ref([])
    const submitting = ref(false)
    const toast = useToast()
    const confirm = useConfirm()

    // Set region
    form.value.region = form.value.region.id

    // Save
    const save = () => {
      submitting.value = true
      errors.value = []

      adminUsersApi
        .saveUser(props.user.id, form.value)
        .then((response) => {
          form.value = response.data.user
          submitting.value = false
          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 5000,
          })
        })
        .catch((error) => {
          errors.value = error.response.data.errors
          submitting.value = false
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    // Delete user
    const deleteUser = () => {
      confirm.require({
        group: 'approve',
        header: i18n.global.t('userDeleteConfirmTitle'),
        message: i18n.global.t('confirmDeleteUser'),
        accept: () => {
          adminUsersApi.deleteUser(props.user.id).then(() => {
            router.push('/admin/users')
          })
        },
      })
    }

    return { form, save, errors, submitting, deleteUser }
  },
}
</script>
