import { defineStore } from 'pinia'
import adminCategoryApi from '@/api/admin/category'

// Add new category to the tree by parent id
function findAndAddCategory(categories, parentId) {
  const id = (Math.random() + 1).toString(36)
  const newCategory = {
    id: id,
    name: null,
    subCategories: [],
    options: [],
    parent: parentId,
    isActive: true,
    isNew: true,
  }

  // Основная категория
  if (!parentId) {
    categories.push(newCategory)
    return true
  }

  for (let category of categories) {
    // Если текущий элемент имеет нужный id
    if (category.id === parentId) {
      // Добавляем объект в массив subCategories
      if (!category.subCategories) {
        category.subCategories = [] // Создаем массив, если его нет
      }
      category.subCategories.push(newCategory)
      return true // Возвращаем true, чтобы остановить рекурсию
    }

    // Если у текущего элемента есть подкатегории, рекурсивно ищем в них
    if (category.subCategories && category.subCategories.length > 0) {
      if (findAndAddCategory(category.subCategories, parentId)) {
        return true // Если элемент найден и изменен, прекращаем поиск
      }
    }
  }

  return false
}

// Remove category by id
function removeCategoryById(categories, id) {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === id) {
      categories.splice(i, 1)
      return true
    }

    if (categories[i].subCategories && categories[i].subCategories.length > 0) {
      if (removeCategoryById(categories[i].subCategories, id)) {
        return true
      }
    }
  }
  return false
}

// Show/hide subcategories
function showHideSubCategories(categories, id, show) {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === id) {
      categories[i].showSubcategories = show
      return true
    }

    if (categories[i].subCategories && categories[i].subCategories.length > 0) {
      if (showHideSubCategories(categories[i].subCategories, id, show)) {
        return true
      }
    }
  }
  return false
}

// Mark category to delete
function markCategoryToDelete(categories, id, toDelete) {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === id) {
      categories[i].toDelete = toDelete
      return true
    }

    if (categories[i].subCategories && categories[i].subCategories.length > 0) {
      if (markCategoryToDelete(categories[i].subCategories, id, toDelete)) {
        return true
      }
    }
  }
  return false
}

export const useCategoryStore = defineStore('category', {
  state: () => ({
    categories: [],
    deletedCategories: [],
    editedCategories: [],
    newCategories: [],
  }),

  actions: {
    async getCategories() {
      const response = await adminCategoryApi.getCategories()
      this.setCategories(response.data)
    },
    setCategories(data) {
      this.categories = data
    },
    addCategory(parentId) {
      findAndAddCategory(this.categories, parentId)
      this.showHideSubCategories(parentId, true)
      // this.newCategories.push(newCategory)
    },
    removeCategory(id) {
      // Remove from new categories
      const index = this.newCategories.findIndex((item) => item.id === id)

      if (index !== -1) {
        this.newCategories.splice(index, 1)
      }

      removeCategoryById(this.categories, id)
    },
    markCategoryToDelete(id, toDelete) {
      markCategoryToDelete(this.categories, id, toDelete)

      if (toDelete) {
        this.deletedCategories.push(id)
      } else {
        const index = this.toDelete.indexOf(id)
        if (index !== -1) {
          this.toDelete.splice(index, 1)
        }
      }
    },
    showHideSubCategories(id, show) {
      showHideSubCategories(this.categories, id, show)
    },
    async saveCategories() {
      const response = await adminCategoryApi.saveCategories(this.categories)
      return response.data
    },
  },
})
