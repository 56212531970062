import api from '../axios'

const adminCountryApi = {
  // Get countries
  getCountries() {
    return api.get('admin/countries')
  },
  // Get regions by country id
  getRegionsByCountryId(id) {
    return api.get(`admin/regions/${id}`)
  },
  // Save country info
  saveCountryInfo(id, data) {
    return api.patch(`admin/countries/${id}`, data)
  },
  // Save regions
  saveRegions(countryId, data) {
    return api.post(`admin/regions/${countryId}`, data)
  },
}

export default adminCountryApi
