<template>
  <Dialog
    v-model:visible="show"
    modal
    :header="status == 1 ? $t('tenderActivation') : $t('offTender')"
  >
    <div v-if="status == 1" class="grid grid-cols-2 gap-8 mb-5">
      <div>
        <label class="block">{{ $t('date_beg') }}</label>
        <Calendar
          v-model="form.date_beg"
          showIcon
          dateFormat="dd.mm.yy"
          class="mt-2 w-full"
          showTime
          hourFormat="24"
          :minDate="new Date()"
        />
        <div v-if="errors.date_beg">
          <small
            v-for="(error, i) in errors.date_beg"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
      <div>
        <label class="block">{{ $t('date_end') }}</label>
        <Calendar
          v-model="form.date_end"
          showIcon
          dateFormat="dd.mm.yy"
          class="mt-2 w-full"
          showTime
          hourFormat="24"
          :minDate="form.date_beg"
        />
        <div v-if="errors.date_end">
          <small
            v-for="(error, i) in errors.date_end"
            :key="i"
            class="p-error"
            >{{ error }}</small
          >
        </div>
      </div>
    </div>
    <div v-if="status == 0">
      <p>{{ $t('sure_hide_tender') }}</p>
    </div>
    <template #footer>
      <Button
        :label="$t('cancel')"
        @click="$emit('hide')"
        text
        class="p-button-secondary p-button-text"
      />
      <Button
        :label="status == 1 ? $t('activate') : $t('offTender')"
        @click="save"
        autofocus
        class="mr-0"
        :loading="submitting"
      />
    </template>
  </Dialog>
</template>

<script>
import { ref, watch } from 'vue'
import Calendar from 'primevue/calendar'
import tenderApi from '@/api/tender'

export default {
  props: ['visible', 'status', 'id'],
  components: { Calendar },
  setup(props, context) {
    const show = ref(props.visible)
    const form = ref({
      status: null,
      date_beg: null,
      date_end: null,
    })
    const errors = ref([])
    const submitting = ref(false)

    watch(
      () => props.visible,
      (first) => {
        show.value = first
      }
    )

    // Submit
    const save = () => {
      submitting.value = true
      form.value.status = props.status
      tenderApi
        .setStatus(props.id, form.value)
        .then(() => {
          // TODO: toast message!
          show.value = false
          submitting.value = false
          context.emit('statusUpdated')
        })
        .catch((error) => {
          submitting.value = false
          errors.value = error.response.data.errors
        })
    }

    return { show, form, errors, submitting, save }
  },
}
</script>
