<template>
  <Toast position="bottom-right" />
  <div>
    <Card>
      <template #title>{{ $t('nav.categories') }}</template>
      <template #content>
        <CategoryTree :categories="categoryStore.categories" />
        <hr class="mt-6 mb-6" />
        <Button
          label="Сохранить"
          icon="pi pi-save"
          size="small"
          @click="saveCategories"
          :loading="submitting"
        />
      </template>
    </Card>
  </div>
</template>

<script>
// import UnderConstruction from '@/components/blocks/UnderConstruction.vue'
// import { ref, onBeforeMount } from 'vue'
import adminCategoryApi from '@/api/admin/category'
import CategoryTree from '@/components/admin/category/CategoryTree.vue'
import { useCategoryStore } from '@/store/category'
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import i18n from '@/i18n'

export default {
  components: { CategoryTree },
  setup() {
    const toast = useToast()
    const submitting = ref(false)
    const categoryStore = useCategoryStore()
    categoryStore.getCategories()

    const saveCategories = () => {
      submitting.value = true

      adminCategoryApi
        .saveCategories(categoryStore.categories)
        .then((response) => {
          submitting.value = false
          categoryStore.getCategories()
          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 5000,
          })
        })
        .catch((error) => {
          submitting.value = false
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    return { submitting, categoryStore, saveCategories }
  },
}
</script>
