import api from '../axios'

const adminUsersApi = {
  // Get users list
  getUsers(query) {
    const q = query ? `?${query}` : ''
    return api.get('/admin/users' + q)
  },
  // Get user info
  getUser(id) {
    return api.get(`/admin/users/${id}`)
  },
  // Save user
  saveUser(id, data) {
    return api.patch(`/admin/users/${id}`, data)
  },
  // Delete user
  deleteUser(id) {
    return api.delete(`/admin/users/${id}`)
  },
}

export default adminUsersApi
