<template>
  <div class="mb-4">
    <!-- Search -->
    <span class="p-input-icon-left w-4/12 mr-3">
      <i class="pi pi-search" />
      <InputText
        type="text"
        v-model="search"
        :placeholder="$t('search')"
        class="w-full"
        @keyup.enter="applyFilters"
      />
    </span>
    <Dropdown
      v-model="accountType"
      :options="accountTypes"
      optionLabel="name"
      optionValue="value"
      :placeholder="$t('accountType')"
      :editable="false"
      class="mr-3"
    />
    <Dropdown
      v-model="status"
      :options="statuses"
      optionLabel="name"
      optionValue="value"
      :placeholder="$t('status')"
      :editable="false"
      class="mr-3"
    />
    <!-- Show filters -->
    <Button
      @click="applyFilters"
      :label="$t('search')"
      icon="pi pi-search"
      class="mr-4"
    />
    <Button
      :label="$t('reset')"
      @click="resetFilters"
      severity="secondary"
      class="p-button-secondary p-button-text"
    />
  </div>

  <Dialog
    v-model:visible="showFilters"
    modal
    :header="$t('filter')"
    position="top"
    :style="{ width: '30vw' }"
  >
    <!-- Brand -->
    <div class="mb-3">
      <FormInputText
        v-model="filters['f[brand][like]']"
        :id="`brand`"
        :placeholder="$t('brand')"
      />
    </div>

    <!-- Owner type -->
    <div class="mb-3">
      <div class="flex flex-wrap gap-10">
        <div class="flex align-items-center">
          <Checkbox
            v-model="userType"
            inputId="userType1"
            name="userType"
            value="1"
            class="mt-1"
          />
          <label for="userType1" class="ml-2">{{ $t('customer') }}</label>
        </div>
        <div class="flex align-items-center">
          <Checkbox
            v-model="userType"
            inputId="userType2"
            name="userType"
            value="2"
            class="mt-1"
          />
          <label for="userType2" class="ml-2">{{ $t('producer') }}</label>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('reset')"
        class="p-button-secondary p-button-outlined"
        outlined
        icon="pi pi-times"
        @click="resetFilters"
        text
      />
      <Button :label="$t('apply')" icon="pi pi-check" @click="applyFilters" />
    </template>
  </Dialog>
</template>

<script>
import { ref } from 'vue'
import FormInputText from '@/components/form/FormInputText.vue'
import i18n from '@/i18n'
import queryString from 'query-string'
import router from '@/router'

export default {
  components: { FormInputText },
  setup(props, context) {
    const filters = ref({})
    const search = ref()
    const userType = ref()
    const accountType = ref()
    const accountTypes = [
      {
        name: i18n.global.t('customer'),
        value: 1,
      },
      {
        name: i18n.global.t('producer'),
        value: 2,
      },
    ]
    const status = ref()
    const statuses = [
      {
        name: i18n.global.t('active'),
        value: 1,
      },
      {
        name: i18n.global.t('inactive'),
        value: 0,
      },
    ]

    // Apply filters
    const applyFilters = () => {
      // Account type
      filters.value['f[type][eq]'] = accountType.value

      // Status
      filters.value['f[status][eq]'] = status.value

      // Search
      filters.value['f[search][ilike]'] = search.value

      // Push query
      const query = queryString.stringify(filters.value)
      router.push(`/admin/users?${query}`)
      context.emit('applyFilters', query)
    }

    // Reset filters
    const resetFilters = () => {
      filters.value = {}
      search.value = null
      status.value = null
      accountType.value = null
      router.push('/admin/users')
      context.emit('applyFilters')
    }

    return {
      filters,
      search,
      userType,
      accountType,
      accountTypes,
      status,
      statuses,
      applyFilters,
      resetFilters,
    }
  },
}
</script>
