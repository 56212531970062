<template>
  <Toast position="bottom-right" />
  <div>
    <Card>
      <template #title>Страны и регионы</template>
      <template #content>
        <Dropdown
          v-model="country"
          :options="countries"
          optionLabel="name"
          optionValue="code"
          :placeholder="$t('selectCountry')"
          :editable="true"
          @change="onCountrySelect"
        />
        <!-- Edit country -->
        <template v-if="currentCountry">
          <!-- Country info -->
          <div class="border bg-slate-50 mt-5 p-5 pt-0 rounded-md">
            <div class="flex gap-5 mt-5">
              <div>
                <FormInputText
                  v-model="currentCountry.name"
                  :id="`name`"
                  :label="$t('countryName')"
                />
              </div>
              <div>
                <FormInputText
                  v-model="currentCountry.code"
                  :id="`name`"
                  :label="$t('countryCode')"
                />
              </div>
              <div>
                <FormInputText
                  v-model="currentCountry.phone_code"
                  :id="`name`"
                  :label="$t('phoneCode')"
                />
              </div>
              <div>
                <label class="block mb-2">Статус</label>
                <Dropdown
                  v-model="currentCountry.status"
                  :options="countryStatuses"
                  optionLabel="name"
                  optionValue="status"
                  :placeholder="$t('selectStatus')"
                  :editable="true"
                />
              </div>
            </div>
            <hr class="mt-6 mb-6" />
            <Button
              label="Сохранить"
              icon="pi pi-save"
              size="small"
              @click="editCountry"
              :loading="submitting"
            />
          </div>
          <!-- Regions -->
          <div class="border bg-slate-50 mt-5 p-5 rounded-md">
            <h3 class="text-xl mb-5 font-bold">Регионы</h3>
            <div class="max-w-screen-xl">
              <div
                v-for="region in regions"
                :key="region.id"
                class="region p-2"
                :class="{ 'bg-red-100': region.status === 0 }"
              >
                <div class="flex gap-3">
                  <div class="flex-1">
                    <InputText
                      type="text"
                      v-model="region.name"
                      class="w-full"
                      :disabled="region.status === 0"
                    />
                  </div>
                  <div class="flex-none">
                    <Button
                      v-if="region.status === 1"
                      label=""
                      icon="pi pi-trash"
                      severity="danger"
                      class="p-button-danger p-button-outlined"
                      @click="removeRegion(region, true)"
                    />
                    <Button
                      v-if="region.status === 0"
                      label=""
                      icon="pi pi-undo"
                      severity="danger"
                      class="p-button-danger p-button-outlined"
                      @click="removeRegion(region, false)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              label="Добавить регион"
              icon="pi pi-plus"
              size="small"
              class="p-button-outlined p-button-small p-button-secondary mt-5"
              @click="addRegion"
            />
            <hr class="mt-6 mb-6" />
            <Button
              label="Сохранить"
              icon="pi pi-save"
              size="small"
              @click="editRegions"
              :loading="submittingRegions"
            />
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref } from 'vue'
import adminCountryApi from '@/api/admin/country'
import FormInputText from '@/components/form/FormInputText.vue'
import { useToast } from 'primevue/usetoast'
import i18n from '@/i18n'

export default {
  components: { FormInputText },
  setup() {
    const countries = ref([])
    const country = ref(null)
    const currentCountry = ref(null)
    const regions = ref([])
    const submitting = ref(false)
    const submittingRegions = ref(false)
    const toast = useToast()

    // Get countries list
    adminCountryApi.getCountries().then((response) => {
      countries.value = response.data
    })

    const onCountrySelect = (event) => {
      currentCountry.value = countries.value.filter((country) => {
        return country.code === event.value
      })[0]

      adminCountryApi
        .getRegionsByCountryId(currentCountry.value.id)
        .then((response) => {
          regions.value = response.data
        })
    }

    // Edit country
    const editCountry = () => {
      submitting.value = true

      adminCountryApi
        .saveCountryInfo(currentCountry.value.id, currentCountry.value)
        .then((response) => {
          submitting.value = false
          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 5000,
          })
        })
        .catch((error) => {
          submitting.value = false
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    // Edit regions
    const editRegions = () => {
      submittingRegions.value = true

      adminCountryApi
        .saveRegions(currentCountry.value.id, { regions: regions.value })
        .then((response) => {
          submittingRegions.value = false

          adminCountryApi
            .getRegionsByCountryId(currentCountry.value.id)
            .then((response) => {
              regions.value = response.data
            })

          toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 5000,
          })
        })
        .catch((error) => {
          submittingRegions.value = false
          toast.add({
            severity: 'error',
            summary: i18n.global.t('error'),
            detail: error.response.data.message,
            life: 5000,
          })
        })
    }

    const removeRegion = (regionToRemove) => {
      if (regionToRemove.isNew) {
        const index = regions.value.findIndex(
          (region) => region.id === regionToRemove.id
        )
        if (index !== -1) {
          regions.value.splice(index, 1)
        }
      } else {
        const region = regions.value.find(
          (region) => region.id === regionToRemove.id
        )
        if (region) {
          region.status = region.status === 1 ? 0 : 1
        }
      }
    }

    const addRegion = () => {
      regions.value.push({
        id: (Math.random() + 1).toString(36),
        name: null,
        code: null,
        status: 1,
        isNew: true,
      })
    }

    const countryStatuses = [
      {
        name: 'Активна',
        status: 1,
      },
      {
        name: 'Не активна',
        status: 0,
      },
    ]

    return {
      countries,
      country,
      regions,
      currentCountry,
      submitting,
      submittingRegions,
      countryStatuses,
      onCountrySelect,
      editCountry,
      editRegions,
      removeRegion,
      addRegion,
    }
  },
}
</script>
