<template>
  <Dialog v-model:visible="show" modal :header="$t('tender_delete')">
    <div>
      <p>{{ $t('sure_delete_tender') }}</p>
    </div>
    <template #footer>
      <Button
        :label="$t('cancel')"
        @click="$emit('hide')"
        text
        class="p-button-secondary p-button-text"
      />
      <Button
        :label="$t('delete')"
        @click="deleteTender"
        autofocus
        class="mr-0 p-button-danger"
        :loading="submitting"
      />
    </template>
  </Dialog>
</template>

<script>
import { ref, watch } from 'vue'
import tenderApi from '@/api/tender'

export default {
  props: ['visible', 'id'],
  setup(props, context) {
    const show = ref(props.visible)
    const errors = ref([])
    const submitting = ref(false)

    watch(
      () => props.visible,
      (first) => {
        show.value = first
      }
    )

    // Delete
    const deleteTender = () => {
      submitting.value = true
      tenderApi
        .delete(props.id)
        .then(() => {
          submitting.value = true
          context.emit('deleted')
        })
        .catch((error) => {
          submitting.value = true
          errors.value = error.response.data.errors
        })
    }

    return { show, errors, submitting, deleteTender }
  },
}
</script>
