import api from '../axios'

const adminCategoryApi = {
  // Get categories
  getCategories() {
    return api.get('/admin/categories')
  },
  // Save
  saveCategories(data) {
    return api.post('/admin/categories', data)
  },
}

export default adminCategoryApi
