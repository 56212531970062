export default {
  "nav": {
    "tenders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тендеры"])},
    "myTenders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои тендеры"])},
    "activeTenders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявки"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дизайн"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрирование"])},
    "regions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны и регоины"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])}
  },
  "company": {
    "identifier0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
    "identifier1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
    "identifier2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКПО"])},
    "identifier3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОГРН"])},
    "identifier4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКВЭД"])}
  },
  "userIdentifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
  "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
  "signUpNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться сейчас"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "passwordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите пароль"])},
  "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить меня"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
  "fullNameReg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО представителя юр. лица"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование юр. лица"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])},
  "agreeWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласен с"])},
  "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["правилами"])},
  "agreePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["площадки"])},
  "agreePersonal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обработки персональных данных"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите страну"])},
  "selectRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите регион"])},
  "accountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип аккаунта"])},
  "selectAccountType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип аккаунта"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказчик"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Производитель"])},
  "jurForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правовая форма"])},
  "juridical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юр. лицо"])},
  "physical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Физ. лицо"])},
  "companyNameExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например: «ООО Название Компании», «ИП Иванов Иван Иванович»"])},
  "needToSelectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо выбрать страну"])},
  "needToSelectRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необходимо выбрать регион"])},
  "passwordConfirmError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введённые пароли не совпадают"])},
  "underConstuction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел в разработке"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неактивен"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["активен"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смена пароля"])},
  "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "myCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои компании"])},
  "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить компанию"])},
  "physicalIdentifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
  "addingComany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление компании"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить адрес"])},
  "requisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты"])},
  "addRequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить реквизиты"])},
  "noAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет адресов"])},
  "noRequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет реквизитов"])},
  "juAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридический адрес"])},
  "factAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический адрес"])},
  "postAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый адрес"])},
  "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Директор"])},
  "accountant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гл. бухгалтер"])},
  "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Р/С"])},
  "cor_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К/С"])},
  "bik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИК"])},
  "userType1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказчик"])},
  "userType2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Производитель"])},
  "companyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите e-mail"])},
  "regPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придумайте пароль"])},
  "tendersTable": {
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
    "date_beg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказчик"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])}
  },
  "addTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать тендер"])},
  "addTenderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание тендера"])},
  "date_beg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "selectCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию"])},
  "productType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип товара"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
  "selectSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подкатегорию"])},
  "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подкатегория"])},
  "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
  "additionalAttrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доп. атрибуты"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])},
  "selectFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файлы..."])},
  "pack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упаковка (см)"])},
  "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ширина"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высота"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "createTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать тендер"])},
  "tenderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тендер №"])},
  "tenderStatus0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыт"])},
  "tenderStatus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создан"])},
  "tenderStatus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбран производитель"])},
  "tenderStatus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписание договора"])},
  "tenderStatus4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В производстве"])},
  "tenderStatus5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отгружается"])},
  "tenderStatus6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ждёт отправки"])},
  "tenderStatus7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В пути"])},
  "tenderStatus8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставлен"])},
  "activeTenders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные"])},
  "closedTenders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершённые"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация"])},
  "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документы"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чат"])},
  "sendTenderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить заявку на участие"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создан"])},
  "chosenManufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбран производитель"])},
  "signingContrcat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписание договора"])},
  "inProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В производстве"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отгружается"])},
  "waitingDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ждёт отправки"])},
  "inDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В пути"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доставлен"])},
  "defaultCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная компания"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "offTender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снять тендер"])},
  "activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать"])},
  "tenderActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активация тендера"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "sure_hide_tender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите снять тендер? Тендер в будущем можно будет активировать."])},
  "tender_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление тендера"])},
  "sure_delete_tender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите удаление тендера."])},
  "request_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма предложения"])},
  "execution_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время выполнения"])},
  "tender_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на участие в тендере"])},
  "send_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить заявку"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рейтинг"])},
  "tenders_finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тендеров завершено"])},
  "tenders_refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказы"])},
  "select_executor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать исполнителя"])},
  "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
  "confirmExecutorApprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы подтверждаете выбор исполнителя?"])},
  "executorConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор производителя"])},
  "confirmExecutorDeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить заявку от производителя?"])},
  "executorDeny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить заявку"])},
  "requestApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка одобрена и ожидает подтверждения производителем."])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "requestDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка отклонена заказчиком."])},
  "messageUserStatusInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт неактивен. Дождитесь активации аккаунта администратором."])},
  "cantCreateTenderUnactiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно создать тендер. Ваш аккаунт ещё не активирован модератором."])},
  "fromCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От заказчика"])},
  "fromproducer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От производителя"])},
  "requestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка отпарвлена"])},
  "requstSuccessfullySent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на участие в тендере успешно отправлена."])},
  "requestsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявки на участие в тендере"])},
  "noRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявок на тендер ещё не поступало."])},
  "requestPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на рассотрении"])},
  "approveTenderExecute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить участие в тендере"])},
  "doyouApproveRequestExecute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы подтверждаете своё участие в тендере?"])},
  "approveExecute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение участия"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "executionApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на участие подтверждена исполнителем."])},
  "executor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнитель"])},
  "drag_files_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перетащите сюда файлы для загрузки."])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
  "uploadFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить файлы"])},
  "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название файла"])},
  "fileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
  "uploadedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружено"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "start_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартовая цена"])},
  "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена без торгов"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
  "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложить цену"])},
  "bidsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список предложений"])},
  "selectBuyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать покупателя"])},
  "buyerConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение покупателя"])},
  "confirmBuyerApprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы подтверждаете выбор покупателя?"])},
  "bidApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложение одобрено"])},
  "chosenBuyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбран покупатель"])},
  "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупатель"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
  "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет новых уведомлений"])},
  "addDesignRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать заявку"])},
  "addDesignRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание заявки на разработку дизайна"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка"])},
  "designRequestNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая"])},
  "designRequestActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В работе"])},
  "designRequestFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершена"])},
  "designRequestCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменена"])},
  "designRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на дизайн"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
  "myTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои обращения"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "ticketSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обращение отправлено"])},
  "ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обращение"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
  "tender_notify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о новых тендерах"])},
  "activeTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные тикеты"])},
  "closedTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытые тикеты"])},
  "supportStatus0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый"])},
  "supportStatus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
  "supportStatus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыт"])},
  "countryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название страны"])},
  "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код страны"])},
  "phoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефонный код"])},
  "countryActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна активна"])},
  "selectStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите статус"])},
  "userDeleteConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление пользователя"])},
  "confirmDeleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить пользователя?"])}
}