<template>
  <div
    v-for="category in categories"
    :key="category.id"
    class="category"
    :class="{ 'bg-red-100': category.toDelete }"
  >
    <div class="flex space-x-4 mb-3">
      <div class="flex-none">
        <!-- Hide subcategories -->
        <Button
          v-if="category.showSubcategories"
          label=""
          icon="pi pi-angle-down"
          class="p-button-secondary p-button-outlined"
          :disabled="category.subCategories.length === 0 || isDisabled"
          @click="showHideSubCategories(category.id, false)"
        />
        <!-- Show subcategories -->
        <Button
          v-if="!category.showSubcategories"
          label=""
          icon="pi pi-angle-right"
          class="p-button-secondary p-button-outlined"
          :disabled="category.subCategories.length === 0 || isDisabled"
          @click="showHideSubCategories(category.id, true)"
        />
      </div>
      <div class="flex-1">
        <InputText
          type="text"
          v-model="category.name"
          class="w-full"
          :disabled="category.toDelete || isDisabled"
        />
      </div>
      <div class="flex-none">
        <Button
          v-if="!category.toDelete"
          label=""
          icon="pi pi-trash"
          severity="danger"
          class="p-button-danger p-button-outlined"
          @click="removeCategory(category, true)"
        />
        <Button
          v-if="category.toDelete"
          label=""
          icon="pi pi-undo"
          severity="danger"
          class="p-button-danger p-button-outlined"
          @click="removeCategory(category, false)"
        />
      </div>
    </div>
    <!-- Sub categories -->
    <CategoryTree
      v-if="category.subCategories.length && category.showSubcategories"
      :categories="category.subCategories"
      :isSubCategory="true"
      :isDisabled="category.toDelete"
      @addCategory="addCategory"
    />
    <div>
      <Button
        label="Добавить подкатегорию"
        icon="pi pi-plus"
        size="small"
        class="p-button-outlined p-button-small p-button-secondary"
        @click="addCategory(category.id)"
        :disabled="category.toDelete || isDisabled"
      />
    </div>
  </div>
  <div v-if="!isSubCategory">
    <Button
      label="Добавить категорию"
      icon="pi pi-plus"
      size="small"
      class="p-button-outlined p-button-small p-button-secondary"
      @click="addCategory(null)"
    />
  </div>
</template>

<script>
import { useCategoryStore } from '@/store/category'

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
    isSubCategory: {
      type: Boolean,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const categoryStore = useCategoryStore()

    const addCategory = (parentId) => {
      categoryStore.addCategory(parentId)
    }

    const removeCategory = (category, toDelete) => {
      if (category.isNew) {
        categoryStore.removeCategory(category.id)
      } else {
        categoryStore.markCategoryToDelete(category.id, toDelete)
      }
    }

    const showHideSubCategories = (id, show) => {
      categoryStore.showHideSubCategories(id, show)
    }
    return { addCategory, removeCategory, showHideSubCategories }
  },
}
</script>
